import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    natomasImage: file(relativePath: { eq: "natomas.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function NatomasPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Mobile Locksmith in Natomas, CA | 916-995-0135"
      description="If you're locked out, lost your keys, or need other locksmith services, call Swift Locksmith at 916-995-0135. Licensed techs, fast response & affordable rates."
      offset
    >
      <ContactHeading background={data.natomasImage.childImageSharp.fluid}>
        <h1 className="text-center">24 Hour Locksmith in Natomas</h1>
        <h2 className="text-center font-weight-light">
          Your Local Locksmith That You Can Trust
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2>Welcome to Swift Locksmith in Natomas, CA</h2>
        <p className="indent mb-0">
          Swift Locksmith provides all of your locksmith needs. We specialize in
          emergency, residential, commercial and automotive locksmith services.
          Our team of highly trained and professional technicians are available
          24 hours a day, seven days a week to help you with any locksmith need
          that you may have. As a family-owned and local locksmith, we have been
          providing outstanding locksmith service to the community of{" "}
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Swift+Locksmith/@38.6672503,-121.7742215,9z/data=!3m1!4b1!4m5!3m4!1s0x809b298eab533cc9:0x3db5c31712015e78!8m2!3d38.6675149!4d-121.2138125"
          >
            Natomas, CA
          </a>{" "}
          and the surrounding areas for over 10 years. Whether you have an
          emergency or non-emergency locksmith need, you can always trust Swift
          Locksmith to take care of you in a timely manner and at an affordable
          price.
        </p>
      </Container>
      <section>
        <div className="background-content bg-dark text-white pt-0 pt-5 pb-md-6">
          <Container>
            <Row>
              <Col xs={12} md={4} className="col-sm-pad">
                <Img
                  fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
                  alt="leo-with-rekey-set"
                />
                <h2 className="text-center mt-3">Residential</h2>
                <ul>
                  <li>24/7 Emergency Lockout</li>
                  <li>Lock Repair & Maintenance</li>
                  <li>Door Knob Lock Installation</li>
                  <li>Duplicate House Keys</li>
                  <li>High Security Locks</li>
                  <li>Master Key Systems</li>
                  <li>On-Site Key Extraction</li>
                  <li>Whole House Re-key</li>
                </ul>
              </Col>
              <Col xs={12} md={4} className="col-sm-pad">
                <Img
                  fluid={data.leoInCarImage.childImageSharp.fluid}
                  alt="leo-in-car"
                />
                <h2 className="text-center mt-3">Automotive</h2>
                <ul>
                  <li>24/7 Lock Out Services</li>
                  <li>Broken Key Extraction</li>
                  <li>Ignition Rekey</li>
                  <li>Laser Cut Keys</li>
                  <li>Chip Keys Cut Services</li>
                  <li>Remote Programming</li>
                  <li>Lost Car Key Replacement</li>
                  <li>Replace Keyless Entry Remotes</li>
                </ul>
              </Col>
              <Col xs={12} md={4} className="col-sm-pad">
                <Img
                  fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
                  alt="locksmith-van-in-city"
                />
                <h2 className="text-center mt-3">Commercial</h2>
                <ul>
                  <li>24/7 Emergency Lockout</li>
                  <li>Business Re-key</li>
                  <li>Locks & Exit Device Repair</li>
                  <li>Master Key system Installation</li>
                  <li>Keyless Access System</li>
                  <li>Deadbolts, High Security Deadbolts</li>
                  <li>On-site Key Duplication</li>
                  <li>Aluminum Door Lock Installation</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Choosing Swift Locksmith</h2>
        <Row className="justify-content-center">
          <Col xs={12} lg={8}>
            <p className="indent">
              Our goal is to give you the highest quality of customer service
              and competitive pricing in all of Natomas, CA. Our team has the
              expertise in all service, installation, and repair of any locks
              and keys so you can rely on us to get the job done. Our first
              priority is our customers needs therefore you can always expect to
              receive a quick response and the best assistance from our team. As
              a company built on honesty, we will provide all the services and
              pricing to you before the job is started so you will never see any
              additional or hidden fees from us. Our locksmith technicians have
              been in the locksmith industry for several years and have the
              expertise, professionalism, and knowledge to assist you.
            </p>
            <p className="indent">
              Whether you are locked out of your car, need to rekey your home or
              need duplicate keys for your business, our technicians come to
              your location with all the necessary tools to assist you. We
              always make sure that you are 100% satisfied with our service and
              we strive to keep our track report flawless. We are proud of the
              work we do and encourage you to see our yelp reviews of customers
              who are satisfied with Swift locksmith. Our Team is always
              available and ready to help you, so please don’t hesitate to call
              and see how we can improve your locksmith security needs today.
            </p>
          </Col>
          <Col xs={12} md={9} lg={4} className="col-sm-pad">
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default NatomasPage
